<template>
  <footer>
    <p>©2021- DigitalHollywood-Kumamoto.</p>
  </footer>
</template>

<style scoped>
  footer {
    background: #FF7000;
    font-size: .8rem;
    padding: 5px 0;
    color: white;
    text-align: center;
  }
</style>